import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
	TextField,
	InputAdornment,
	Button,
} from "@mui/material";
// cmp
import DrawerDialog from "../DrawerDialog";
// hooks
import useShouldAutofocus from "../../hooks/useShouldAutofocus";
// types
import type { FocusEvent } from "react";

type Props = Readonly<{
	open: boolean;
	title: string;
	label: string;
	value: number | null;
	min?: number;
	max?: number;
	step?: number;
	unit?: string;
	onChange: (event: null, value: number) => void;
	onCancel: () => void;
}>;

const NumberPicker = ({ open, title, label, value, min = undefined, max = undefined, step = undefined, unit = undefined, onChange, onCancel }: Props) => {
	const { t } = useTranslation();

	const shouldAutofocus = useShouldAutofocus();

	const [selectedValue, setSelectedValue] = useState(value);

	useEffect(() => {
		if (!open) {
			setSelectedValue(value);
		}
	}, [open, value]);

	const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
		const newValue = Number(event.target.value);
		if (min !== undefined && newValue < min) {
			setSelectedValue(min);
		}
		if (max !== undefined && newValue > max) {
			setSelectedValue(max);
		}
	};

	return (
		<DrawerDialog
			id="dlg-number-picker"
			title={title}
			open={open}
			onClose={onCancel}
			drawerActions={
				<Button
					className="btn-drawer-action-ok"
					variant="contained"
					disabled={selectedValue === null || (min !== undefined && min > selectedValue) || (max !== undefined && selectedValue > max)}
					onClick={() => (onChange(null, selectedValue as number))}
					sx={{ minWidth: "40%", maxWidth: "320px" }}
				>
					{t("dialog.ok")}
				</Button>
			}
			dialogActions={
				<>
					<Button className="btn-dlg-action-cancel" color="inherit" onClick={onCancel}>{t("dialog.cancel")}</Button>
					<Button
						className="btn-dlg-action-ok"
						variant="contained"
						disableElevation={true}
						disabled={selectedValue === null || (min !== undefined && min > selectedValue) || (max !== undefined && selectedValue > max)}
						onClick={() => (onChange(null, selectedValue as number))}
					>
						{t("dialog.ok")}
					</Button>
				</>
			}
		>
			<TextField
				type="number"
				autoFocus={shouldAutofocus}
				label={label}
				slotProps={{
					input: { endAdornment: unit ? <InputAdornment position="end">{unit}</InputAdornment> : undefined },
					htmlInput: {
						min: min,
						max: max,
						step: step,
						inputMode: "numeric",
						style: { textAlign: "right" },
					},
					inputLabel: { shrink: true },
				}}
				value={selectedValue}
				onChange={(event) => (setSelectedValue(Number(event.target.value)))}
				onBlur={handleBlur}
				sx={{ width: "192px", margin: "auto" }}
			/>
		</DrawerDialog>
	);
};

export default NumberPicker;
